const forms = document.querySelectorAll('.form:not(.form--custom)');

forms.forEach(form => {
    new Vue({
        el: form,
        store,
        data: function () {
            return {
                hasCaptcha: false,
                captchaExecuted: false
            }
        },
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        computed: {},
        mounted: function () {
        },
        methods: {
            submit: function () {
                const that = this;
                this.$validator.validateAll().then(result => {
                    let analytics = that.$el.dataset.analytics;

                    if (result) {
                        if (!this.hasCaptcha || this.captchaExecuted) {
                            Global.SubmitForm(that.$el, analytics);
                        } else {
                            this.$refs.recaptcha.execute();
                        }
                    }
                });
            },
            onCaptchaVerified: function () {
                this.captchaExecuted = true;
                this.submit();
            }
        },
        created: function () {
        },
        destroyed: function () {
        }
    });
});

